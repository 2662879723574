<template>
  <!--  <el-col>-->
  <!--    <el-table-->
  <!--        :data="$store.state.pos6"-->
  <!--        style="width: 100%">-->
  <!--      <el-table-column-->
  <!--          prop="partPlace"-->
  <!--          label="Part Place"-->
  <!--          width="180"/>-->
  <!--      <el-table-column-->
  <!--          label="Actions"-->
  <!--          width="180">-->
  <!--        <el-button type="danger">Remove</el-button>-->
  <!--      </el-table-column>-->
  <!--    </el-table>-->
  <!--  </el-col>-->

  <el-table
      :data="$store.state.winnerBox"
      style="width: 100%"
      max-height="100%"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="150"
    >
      <template
          #default="scope"
      >
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
        prop="cars"
        label="Cars Count"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.cars"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="cash"
        label="Cash"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.cash"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="money"
        label="Money"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.money"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="exp"
        label="Exp"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.exp"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        fixed="right"
        label="Operations"
        width="120"
    >
      <template #default="scope">
        <el-button
            type="text"
            size="small"
            @click.prevent="update(scope.row)"
        >
          Save
        </el-button>
        <el-button
            type="text"
            size="small"
            @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-form
      ref="form"
      :model="form"
      label-width="120px"
      style="margin-top: 10px"
  >
    <el-form-item
        label="Car Count"
    >
      <el-input
          v-model="form.cars"
      />
    </el-form-item>
    <el-form-item
        label="Cash"
    >
      <el-input
          v-model="form.cash"
      />
    </el-form-item>
    <el-form-item
        label="Money"
    >
      <el-input
          v-model="form.money"
      />
    </el-form-item>
    <el-form-item
        label="Exp"
    >
      <el-input
          v-model="form.exp"
      />
    </el-form-item>
    <el-form-item>
      <el-button
          type="primary"
          @click="onSubmit"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      form:{
        cars: 0,
        cash: 0,
        money: 0,
        exp: 0
      }
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('addWinnerBox',this.form)
    },
    update(row){
      this.$store.dispatch('updateWinnerBox',{id: row.id, cars: row.cars, cash: row.cash, money: row.money, exp: row.exp})
    },
    deleteRow(row){
      this.$store.dispatch('deleteWinnerBox',{id: row.id})
    }
  }
}
</script>