<template>
  <el-table
      :data="$store.state.partsShop"
      style="width: 100%"
      max-height="100%"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="150"
    >
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
        prop="carId"
        label="Car Id"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.carId"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="category"
        label="Category"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.category"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="itemID"
        label="Item ID"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.itemID"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="order"
        label="Order"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.order"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="buyData.credits"
        label="Buy Data Credits"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.buyData.credits"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="buyData.cash"
        label="Buy Data Cash"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.buyData.cash"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="buyData.saleCredits"
        label="Buy Data Sale Credits"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.buyData.saleCredits"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="buyData.saleCash"
        label="Buy Data Sale Cash"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.buyData.saleCash"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        prop="buyData.expBuy"
        label="Buy Data Exp Buy"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-input
            v-model="scope.row.buyData.expBuy"
            size="small"
            style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
        label="Operations"
        width="120"
    >
      <template
          #default="scope"
      >
        <el-button
            type="text"
            size="small"
            @click.prevent="update(scope.row)"
        >
          Save
        </el-button>
        <el-button
            size="small"
            type="text"
            @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-form
      ref="form"
      :model="partsShop"
      label-width="120px"
      style="margin-top: 10px"
  >
    <el-form-item
        label="Item ID"
    >
      <el-input
          v-model="partsShop.itemID"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="Ex.: 12"
          step="1"
          type="number"
      />
    </el-form-item>
    <el-form-item
        label="Category"
    >
      <el-select
          v-model="partsShop.category"
          placeholder="Category..."
      >
        <el-option
            v-for="(category,key) in categories"
            :key="key"
            :label="category"
            :value="category"
        />
      </el-select>
    </el-form-item>
    <el-form-item
        label="Order"
    >
      <el-input
          v-model="partsShop.order"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="Ex.: 12"
          step="1"
          type="number"
      />
    </el-form-item>
    <el-form-item
        label="Car Id"
    >
      <el-input
          v-model="partsShop.carId"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="Ex.: 12"
          step="1"
          type="number"
      />
    </el-form-item>
    <el-form-item
        label="Buy Data"
    >
      <el-divider />
      <el-form-item>
        <el-divider />
        <el-form-item
            label="Credits"
        >
          <el-input
              v-model="partsShop.buyData.credits"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
          />
        </el-form-item>
        <el-form-item
            label="Cash"
        >
          <el-input
              v-model="partsShop.buyData.cash"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
          />
        </el-form-item>
        <el-form-item
            label="Sale Credits"
        >
          <el-input
              v-model="partsShop.buyData.saleCredits"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
          />
        </el-form-item>
        <el-form-item
            label="Sale Cash"
        >
          <el-input
              v-model="partsShop.buyData.saleCash"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
          />
        </el-form-item>
        <el-form-item
            label="Exp Buy"
        >
          <el-input
              v-model="partsShop.buyData.expBuy"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 10.00"
              step="0.01"
              type="number"
          />
        </el-form-item>
        <el-divider />
      </el-form-item>
      <el-divider />
    </el-form-item>
    <el-form-item>
      <el-button
          type="primary"
          @click="onSubmit"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      categories:[
        "Frame",
        "Exhaust",
        "Bumper",
        "Fender",
        "Hood",
        "Door",
        "Headlight",
        "Seat",
        "Sideskirt",
        "Taillight",
        "Sideskirt",
        "Rollcage",
        "Spoiler",
        "Trunck"
      ],
      partsShop: {
        order: '',
        itemID: '',
        carId: '',
        category: '',
        buyData: {
          cash:"0",
          credits:"0",
          expBuy:"0",
          saleCash:"0",
          saleCredits:"0"
        }
      }
    }
  },
  methods: {
    onSubmit() {
      this.partsShop.carId = parseInt(this.partsShop.carId)
      this.partsShop.order = parseInt(this.partsShop.order)
      this.partsShop.itemID = parseInt(this.partsShop.itemID)
      this.partsShop.buyData.cash = parseInt(this.partsShop.buyData.cash)
      this.partsShop.buyData.credits = parseInt(this.partsShop.buyData.credits)
      this.partsShop.buyData.expBuy = parseInt(this.partsShop.buyData.expBuy)
      this.partsShop.buyData.saleCash = parseInt(this.partsShop.buyData.saleCash)
      this.partsShop.buyData.saleCredits = parseInt(this.partsShop.buyData.saleCredits)
      this.$store.dispatch('addNewPartShop',this.partsShop)
    },
    update(row){
      row.carId = parseInt(row.carId.toString())
      row.order = parseInt(row.order.toString())
      row.itemID = parseInt(row.itemID.toString())
      row.buyData.cash = parseInt(row.buyData.cash.toString())
      row.buyData.credits = parseInt(row.buyData.credits.toString())
      row.buyData.expBuy = parseInt(row.buyData.expBuy.toString())
      row.buyData.saleCash = parseInt(row.buyData.saleCash.toString())
      row.buyData.saleCredits = parseInt(row.buyData.saleCredits.toString())
      this.$store.dispatch('updatePartsShop', row)
    },
    deleteRow(row){
      this.$store.dispatch('deletePartsShop', {id: row.id})
    }
  }
}
</script>
