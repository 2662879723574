<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
  >
    <el-form-item
      label="Link to image"
    >
      <el-input
        v-model="form.imgLink"
        placeholder="http://example"
      />
    </el-form-item>
    <el-form-item
      label="Car model"
    >
      <el-input
        v-model="form.carModel"
        placeholder="Lamborgini aventador"
      />
    </el-form-item>
    <el-form-item
      label="Car ID"
    >
      <el-input
        v-model="form.carID"
        placeholder="Ex.: 12"
        type="number"
      />
    </el-form-item>
    <el-form-item label="Body color">
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.bodyColor[0]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="R"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.bodyColor[1]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="G"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.bodyColor[2]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="B"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.bodyColor[3]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="A"
          step="0.01"
          type="number"
        />
      </el-col>
    </el-form-item>
    <el-form-item
      label="Tint color"
    >
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.tintColor[0]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="R"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.tintColor[1]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="G"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.tintColor[2]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="B"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.tintColor[3]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="A"
          step="0.01"
          type="number"
        />
      </el-col>
    </el-form-item>
    <el-form-item
      label="Underglow color"
    >
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.underglowColor[0]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="R"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.underglowColor[1]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="G"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.underglowColor[2]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="B"
          step="0.01"
          type="number"
        />
      </el-col>
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.underglowColor[3]"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="A"
          step="0.01"
          type="number"
        />
      </el-col>
    </el-form-item>
    <el-form-item
      label="Meterial ID"
    >
      <el-col
        :span="2"
        class="line"
      >
        <el-input
          v-model="form.materialID"
          pattern="[0-9]+([\.,][0-9]+)?"
          placeholder="Ex.: 12"
          step="0.01"
          type="number"
        />
      </el-col>
    </el-form-item>
    <el-form-item
      label="Exp Car"
    >
      <el-input
        v-model="form.expCar"
        pattern="[0-9]+([\.,][0-9]+)?"
        placeholder="Ex.: 1"
        step="0.01"
        type="number"
      />
    </el-form-item>
    <el-form-item label="Body parts">
        <el-divider />
        <el-form-item
          v-for="(_,key) in form.parts"
          :key="key"
        >
          <el-form-item
              label="Type"
          >
            <el-select
                v-model="form.parts[key].type"
                placeholder="Type..."
            >
              <el-option
                  v-for="(category,key) in $store.state.partNames"
                  :key="key"
                  :label="category.partName"
                  :value="category.partName"
              />
            </el-select>
          </el-form-item>


          <el-form-item
            label="Order"
          >
            <el-input
              v-model="form.parts[key].order"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 12"
              step="1"
              type="number"
            />
          </el-form-item>
          <el-form-item
            label="Item ID"
          >
            <el-input
              v-model="form.parts[key].itemID"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 12"
              step="1"
              type="number"
            />
          </el-form-item>
          <el-divider />
          <el-form-item
            label="Buy Data"
          >
            <el-form-item>
              <el-form-item
                label="Credits"
              >
                <el-input
                  v-model="form.parts[key].buyData.credits"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="Ex.: 100"
                  step="0.01"
                  type="number"
                />
              </el-form-item>
              <el-form-item
                label="Cash"
              >
                <el-input
                  v-model="form.parts[key].buyData.cash"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="Ex.: 100"
                  step="0.01"
                  type="number"
                />
              </el-form-item>
              <el-form-item
                label="Sale Credits"
              >
                <el-input
                  v-model="form.parts[key].buyData.saleCredits"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="Ex.: 100"
                  step="0.01"
                  type="number"
                />
              </el-form-item>
              <el-form-item
                label="Sale Cash"
              >
                <el-input
                  v-model="form.parts[key].buyData.saleCash"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="Ex.: 100"
                  step="0.01"
                  type="number"
                />
              </el-form-item>
              <el-form-item
                label="Exp Buy"
              >
                <el-input
                  v-model="form.parts[key].buyData.expBuy"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="Ex.: 10.00"
                  step="0.01"
                  type="number"
                />
              </el-form-item>
            </el-form-item>
            <el-button
              @click="removePartData(key)"
            >
              Remove Part Data
            </el-button>
          </el-form-item>
          <el-divider />
        </el-form-item>
        <el-button
          @click="addPartData"
        >
          Add Part Data
        </el-button>
      <el-col
        :span="12"
      />
    </el-form-item>


    <el-form-item
      label="Wheel Data"
    >
      <el-divider />
      <el-form-item
        v-for="(item, key) in form.wheels"
        :key="key"
        :label="'Body Data ' + key "
      >
        <el-divider />
        <el-form-item label="Item ID">
          <el-input
            v-model="form.wheels[key].WheelID"
          />
        </el-form-item>
        <el-form-item
          label="First color"
        >
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].firstColor[0]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="R"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].firstColor[1]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="G"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].firstColor[2]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="B"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].firstColor[3]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="A"
              step="0.01"
              type="number"
            />
          </el-col>
        </el-form-item>
        <el-form-item
          label="Second color"
        >
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].secondColor[0]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="R"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].secondColor[1]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="G"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].secondColor[2]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="B"
              step="0.01"
              type="number"
            />
          </el-col>
          <el-col
            :span="2"
            class="line"
          >
            <el-input
              v-model="form.wheels[key].secondColor[3]"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="A"
              step="0.01"
              type="number"
            />
          </el-col>
        </el-form-item>
        <el-form-item
          label="Caliper ID"
        >
          <el-input
            v-model="form.wheels[key].caliperID"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 1"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Caliper Pos"
        >
          <el-input
            v-model="form.wheels[key].caliperPos"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 12.00"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Brake Disk Material"
        >
          <el-input
            v-model="form.wheels[key].brakeDiskMat"
            placeholder="Ex.: 12.00"
          />
        </el-form-item>
        <el-form-item
          label="Tire Material"
        >
          <el-input
            v-model="form.wheels[key].tireMat"
            placeholder="Ex.: 12.00"
          />
        </el-form-item>
        <el-form-item
          label="Brake Disk Position"
        >
          <el-input
            v-model="form.wheels[key].brakeDiskPos"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 10.00"
            step="0.01"
            type="number"
          />
        </el-form-item>

        <el-form-item
          label="Buy data"
        >
          <el-divider />
          <!--            <el-form-item-->
          <!--              label="Is Buyed"-->
          <!--            >-->
          <!--              <el-switch-->
          <!--                v-model="form.parts.wheels[key].buyData.isBuyed"-->
          <!--              />-->
          <!--            </el-form-item>-->
          <el-form-item
            label="Credits"
          >
            <el-input
              v-model="form.wheels[key].buyData.credits"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
            />
          </el-form-item>
          <el-form-item
            label="Cash"
          >
            <el-input
              v-model="form.wheels[key].buyData.cash"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
            />
          </el-form-item>
          <el-form-item
            label="Sale Credits"
          >
            <el-input
              v-model="form.wheels[key].buyData.saleCredits"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
            />
          </el-form-item>
          <el-form-item
            label="Sale Cash"
          >
            <el-input
              v-model="form.wheels[key].buyData.saleCash"
              pattern="[0-9]+([\.,][0-9]+)?"
              placeholder="Ex.: 100"
              step="0.01"
              type="number"
            />
          </el-form-item>
          <el-divider />
          <el-button
            @click="removeWheel(key)"
          >
            Remove Wheel
          </el-button>
        </el-form-item>
        <el-divider />
      </el-form-item>
      <el-col
        v-if="form.wheels.length < 2"
        :span="12"
      >
        <el-button
          @click="addWheel"
        >
          New Wheel Data
        </el-button>
      </el-col>
    </el-form-item>
    <el-form-item label="Buy Data">
      <el-divider />
      <el-form-item>
        <el-divider />
        <el-form-item
          label="Credits"
        >
          <el-input
            v-model="form.buyData.credits"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Cash"
        >
          <el-input
            v-model="form.buyData.cash"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Sale Credits"
        >
          <el-input
            v-model="form.buyData.saleCredits"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Sale Cash"
        >
          <el-input
            v-model="form.buyData.saleCash"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Exp Buy"
        >
          <el-input
            v-model="form.buyData.expBuy"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 10.00"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-divider />
      </el-form-item>
      <el-divider />
    </el-form-item>

    <el-form-item
      label="Performance"
    >
      <el-divider />
      <el-form-item>
        <el-divider />
        <el-form-item
          label="Power"
        >
          <el-input
            v-model="form.performance.power"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Ecsceleration"
        >
          <el-input
            v-model="form.performance.ecsceleration"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Torque"
        >
          <el-input
            v-model="form.performance.torque"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Weight"
        >
          <el-input
            v-model="form.performance.weight"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-divider />
      </el-form-item>
      <el-divider />
    </el-form-item>
    <el-form-item>
      <el-button
        v-if="isUpdateMode === false"
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
      <el-button
        v-if="isUpdateMode === true"
        type="primary"
        @click="onUpdate"
      >
        Update
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      isUpdateMode: false,
      submit: {},
      form: {
        expCar: '',
        carModel: '',
        carID: '',
        materialID: '',
        bodyColor: [],
        tintColor: [],
        underglowColor: [],
        performance: {},
        // bodyData: [],
        // wheelData: [],
        buyData: {},
        parts:[],
        wheels: []
      }
    }
  },
  mounted() {
    this.initData();
    if (this.$route.params.id) {
      this.isUpdateMode = true
      let temp = this.$store.state.cars.filter(item => item.id === this.$route.params.id)[0]
      temp.bodyColor = temp.bodyColor.split('/')
      // temp.bodyColor.forEach((item,key) => {
      //   temp.bodyColor[key] = parseInt(temp.bodyColor[key]);
      // })
      temp.tintColor = temp.tintColor.split('/')
      // temp.tintColor.forEach((item,key) => {
      //   temp.tintColor[key] = parseInt(temp.tintColor[key]);
      // })
      temp.underglowColor = temp.underglowColor.split('/')
      // temp.underglowColor.forEach((item,key) => {
      //   temp.underglowColor[key] = parseInt(temp.underglowColor[key]);
      // })
      // temp.bodyData.forEach((item, key) => {
      //   temp.bodyData[key].Pos6 = temp.bodyData[key].Pos6.split('/')
      // })
      temp.wheels.forEach((item, key) => {
        temp.wheels[key].firstColor = temp.wheels[key].firstColor.split('/')
        temp.wheels[key].secondColor = temp.wheels[key].secondColor.split('/')
      })
      this.form = temp
      console.log(this.form)
    }
  },
  methods: {
    initData() {
      this.form.bodyColor[0] = "0.00"
      this.form.bodyColor[1] = "0.00"
      this.form.bodyColor[2] = "0.00"
      this.form.bodyColor[3] = "0.00"

      this.form.tintColor[0] = "0.00"
      this.form.tintColor[1] = "0.00"
      this.form.tintColor[2] = "0.00"
      this.form.tintColor[3] = "0.00"

      this.form.underglowColor[0] = "0.00"
      this.form.underglowColor[1] = "0.00"
      this.form.underglowColor[2] = "0.00"
      this.form.underglowColor[3] = "0.00"
    },


    addWheel(){
      this.form.wheels.push({
          order: "0",
          wheelID : "0",
          brakeDiskMat: "0",
          brakeDiskPos: "0",
          caliperID: "0",
          caliperPos: "0",
          firstColor: [],
          secondColor: [],
          tireMat: "0",
          buyData:{
            cash:"0",
            credits:"0",
            expBuy:"0",
            saleCash:"0",
            saleCredits:"0"
          }
      })
    },
    // calipers: [],
    // doors: [],
    // frame:[],
    // exhaust: [],
    // bumpers: [],
    // spoiler: [],
    // trunck: [],
    // taillights: [],
    // sideskirts: [],
    // seats: [],
    // headlights: [],
    // fenders: []



    addPartData(){
      this.form.parts.push({
        type: '',
        order: 0,
        itemID:0,
        buyData:{
          cash:0,
          credits:0,
          expBuy:0,
          saleCash:0,
          saleCredits:0
        }
      });
    },



    // addRollcage(){
    //   this.form.parts.bodyPartData.rollcage.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addCaliper(){
    //   this.form.parts.bodyPartData.calipers.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addDoor(){
    //   this.form.parts.bodyPartData.doors.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addFrame(){
    //   this.form.parts.bodyPartData.frame.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addExhaust(){
    //   this.form.parts.bodyPartData.exhaust.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addBumper(){
    //   this.form.parts.bodyPartData.bumpers.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addSpoiler(){
    //   this.form.parts.bodyPartData.spoiler.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addTrunck(){
    //   this.form.parts.bodyPartData.trunck.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addTaillights(){
    //   this.form.parts.bodyPartData.taillights.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addSideskirts(){
    //   this.form.parts.bodyPartData.sideskirts.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addSeats(){
    //   this.form.parts.bodyPartData.seats.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addHeadlights(){
    //   this.form.parts.bodyPartData.headlights.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },
    //
    // addFenders(){
    //   this.form.parts.bodyPartData.fenders.push(
    //       {
    //         order: 0,
    //         itemID:0,
    //         buyData:{
    //           cash:0,
    //           credits:0,
    //           expBuy:0,
    //           saleCash:0,
    //           saleCredits:0
    //         }
    //       }
    //   )
    // },




    // removeCaliper(key){
    //   this.form.parts.bodyPartData.calipers = this.form.parts.bodyPartData.calipers.filter((value, k) => k !== key);
    // },
    //
    // removeDoor(key){
    //   this.form.parts.bodyPartData.doors = this.form.parts.bodyPartData.doors.filter((value, k) => k !== key);
    // },
    //
    // removeFrame(key){
    //   this.form.parts.bodyPartData.frame = this.form.parts.bodyPartData.frame.filter((value, k) => k !== key);
    // },
    //
    // removeExhaust(key){
    //   this.form.parts.bodyPartData.exhaust = this.form.parts.bodyPartData.exhaust.filter((value, k) => k !== key);
    // },
    //
    // removeBumper(key){
    //   this.form.parts.bodyPartData.bumpers = this.form.parts.bodyPartData.bumpers.filter((value, k) => k !== key);
    // },
    //
    // removeRollcage(key){
    //   this.form.parts.bodyPartData.rollcage = this.form.parts.bodyPartData.rollcage.filter((value, k) => k !== key);
    // },
    //
    // removeSpoiler(key){
    //   this.form.parts.bodyPartData.spoiler = this.form.parts.bodyPartData.spoiler.filter((value, k) => k !== key);
    // },
    //
    // removeTrunck(key){
    //   this.form.parts.bodyPartData.trunck = this.form.parts.bodyPartData.trunck.filter((value, k) => k !== key);
    // },
    //
    // removeTaillights(key){
    //   this.form.parts.bodyPartData.taillights = this.form.parts.bodyPartData.taillights.filter((value, k) => k !== key);
    // },
    //
    // removeSideskirts(key){
    //   this.form.parts.bodyPartData.sideskirts = this.form.parts.bodyPartData.sideskirts.filter((value, k) => k !== key);
    // },
    //
    // removeSeats(key){
    //   this.form.parts.bodyPartData.seats = this.form.parts.bodyPartData.seats.filter((value, k) => k !== key);
    // },
    //
    // removeHeadlights(key){
    //   this.form.parts.bodyPartData.headlights = this.form.parts.bodyPartData.headlights.filter((value, k) => k !== key);
    // },

    // removeFenders(key){
    //   this.form.parts.bodyPartData.fenders = this.form.parts.bodyPartData.fenders.filter((value, k) => k !== key);
    // },

    removePartData(key) {
      this.form.parts = this.form.parts.filter((value, k) => k !== key);
    },
    removeWheel(key) {
      this.form.wheels = this.form.wheels.filter((value, k) => k !== key);
    },


    onUpdate() {
      this.isUpdateMode = false;

      this.form.carID = parseInt(this.form.carID)
      this.form.expCar = parseInt(this.form.expCar)
      this.form.materialID = parseInt(this.form.materialID)

      this.form.buyData.cash = parseInt(this.form.buyData.cash)
      this.form.buyData.credits = parseInt(this.form.buyData.credits)
      this.form.buyData.saleCash = parseInt(this.form.buyData.saleCash)
      this.form.buyData.saleCredits = parseInt(this.form.buyData.saleCredits)
      this.form.buyData.expBuy =  parseInt(this.form.buyData.expBuy)

      this.form.bodyColor = this.form.bodyColor.join('/')
      this.form.tintColor = this.form.tintColor.join('/')
      this.form.underglowColor = this.form.underglowColor.join('/')


      this.form.wheels.forEach((item, key) => {
        this.form.wheels[key].firstColor = this.form.wheels[key].firstColor.join('/')
        this.form.wheels[key].secondColor = this.form.wheels[key].secondColor.join('/')
        this.form.wheels[key].WheelID = parseInt(this.form.wheels[key].WheelID)
        this.form.wheels[key].brakeDiskMat = parseInt(this.form.wheels[key].brakeDiskMat)
        this.form.wheels[key].brakeDiskPos = parseInt(this.form.wheels[key].brakeDiskPos)
        this.form.wheels[key].caliperID = parseInt(this.form.wheels[key].caliperID)
        this.form.wheels[key].caliperPos = parseInt(this.form.wheels[key].caliperPos)
        this.form.wheels[key].tireMat = parseInt(this.form.wheels[key].tireMat)
        this.form.wheels[key].buyData.cash = parseInt(this.form.wheels[key].buyData.cash)
        this.form.wheels[key].buyData.credits = parseInt(this.form.wheels[key].buyData.credits)
        this.form.wheels[key].buyData.saleCash = parseInt(this.form.wheels[key].buyData.saleCash)
        this.form.wheels[key].buyData.saleCredits = parseInt(this.form.wheels[key].buyData.saleCredits)
      })


      // this.form.parts.bodyPartData.calipers.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.calipers[subKey].itemID = parseInt(this.form.parts.bodyPartData.calipers[subKey].itemID)
      //   this.form.parts.bodyPartData.calipers[subKey].order = parseInt(this.form.parts.bodyPartData.calipers[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.fenders.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.fenders[subKey].itemID = parseInt(this.form.parts.bodyPartData.fenders[subKey].itemID)
      //   this.form.parts.bodyPartData.fenders[subKey].order = parseInt(this.form.parts.bodyPartData.fenders[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.seats.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.seats[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.seats[subKey].itemID = parseInt(this.form.parts.bodyPartData.seats[subKey].itemID)
      //   this.form.parts.bodyPartData.seats[subKey].order = parseInt(this.form.parts.bodyPartData.seats[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.headlights.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.headlights[subKey].itemID = parseInt(this.form.parts.bodyPartData.headlights[subKey].itemID)
      //   this.form.parts.bodyPartData.headlights[subKey].order = parseInt(this.form.parts.bodyPartData.headlights[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.trunck.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.trunck[subKey].itemID = parseInt(this.form.parts.bodyPartData.trunck[subKey].itemID)
      //   this.form.parts.bodyPartData.trunck[subKey].order = parseInt(this.form.parts.bodyPartData.trunck[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.sideskirts.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.sideskirts[subKey].itemID = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].itemID)
      //   this.form.parts.bodyPartData.sideskirts[subKey].order = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.taillights.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.taillights[subKey].itemID = parseInt(this.form.parts.bodyPartData.taillights[subKey].itemID)
      //   this.form.parts.bodyPartData.taillights[subKey].order = parseInt(this.form.parts.bodyPartData.taillights[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.spoiler.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.itemID = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.itemID)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.order = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.order)
      // })
      //
      // this.form.parts.bodyPartData.bumpers.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.bumpers[subKey].itemID = parseInt(this.form.parts.bodyPartData.bumpers[subKey].itemID)
      //   this.form.parts.bodyPartData.bumpers[subKey].order = parseInt(this.form.parts.bodyPartData.bumpers[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.exhaust.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.exhaust[subKey].itemID = parseInt(this.form.parts.bodyPartData.exhaust[subKey].itemID)
      //   this.form.parts.bodyPartData.exhaust[subKey].order = parseInt(this.form.parts.bodyPartData.exhaust[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.frame.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.frame[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.frame[subKey].itemID = parseInt(this.form.parts.bodyPartData.frame[subKey].itemID)
      //   this.form.parts.bodyPartData.frame[subKey].order = parseInt(this.form.parts.bodyPartData.frame[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.doors.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.doors[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.doors[subKey].itemID = parseInt(this.form.parts.bodyPartData.doors[subKey].itemID)
      //   this.form.parts.bodyPartData.doors[subKey].order = parseInt(this.form.parts.bodyPartData.doors[subKey].order)
      // })

      // this.form.parts.bodyPartData.rollcage.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.rollcage[subKey].itemID = parseInt(this.form.parts.bodyPartData.rollcage[subKey].itemID)
      //   this.form.parts.bodyPartData.rollcage[subKey].order = parseInt(this.form.parts.bodyPartData.rollcage[subKey].order)
      // })


      this.form.parts.forEach((subItem, subKey) => {
        this.form.parts[subKey].buyData.cash = parseInt(this.form.parts[subKey].buyData.cash)
        this.form.parts[subKey].buyData.credits = parseInt(this.form.parts[subKey].buyData.credits)
        this.form.parts[subKey].buyData.expBuy = parseInt(this.form.parts[subKey].buyData.expBuy)
        this.form.parts[subKey].buyData.saleCash = parseInt(this.form.parts[subKey].buyData.saleCash)
        this.form.parts[subKey].buyData.saleCredits = parseInt(this.form.parts[subKey].buyData.saleCredits)
        this.form.parts[subKey].itemID = parseInt(this.form.parts[subKey].itemID)
        this.form.parts[subKey].order = parseInt(this.form.parts[subKey].order)
      })



      this.form.performance.power = parseInt(this.form.performance.power)
      this.form.performance.ecsceleration = parseInt(this.form.performance.ecsceleration)
      this.form.performance.torque = parseInt(this.form.performance.torque)
      this.form.performance.weight = parseInt(this.form.performance.weight)

      this.$store.dispatch('updateCar', this.form)
      console.log(this.form.bodyColor)
      console.log('submit!');
    },
    onSubmit() {
      this.form.carID = parseInt(this.form.carID)
      this.form.expCar = parseInt(this.form.expCar)
      this.form.materialID = parseInt(this.form.materialID)

      this.form.buyData.cash = parseInt(this.form.buyData.cash)
      this.form.buyData.credits = parseInt(this.form.buyData.credits)
      this.form.buyData.saleCash = parseInt(this.form.buyData.saleCash)
      this.form.buyData.saleCredits = parseInt(this.form.buyData.saleCredits)
      this.form.buyData.expBuy =  parseInt(this.form.buyData.expBuy)

      this.form.bodyColor = this.form.bodyColor.join('/')
      this.form.tintColor = this.form.tintColor.join('/')
      this.form.underglowColor = this.form.underglowColor.join('/')

      this.form.wheels.forEach((item, key) => {
        this.form.wheels[key].firstColor = this.form.wheels[key].firstColor.join('/')
        this.form.wheels[key].secondColor = this.form.wheels[key].secondColor.join('/')
        this.form.wheels[key].WheelID = parseInt(this.form.wheels[key].WheelID)
        this.form.wheels[key].brakeDiskMat = parseInt(this.form.wheels[key].brakeDiskMat)
        this.form.wheels[key].brakeDiskPos = parseInt(this.form.wheels[key].brakeDiskPos)
        this.form.wheels[key].caliperID = parseInt(this.form.wheels[key].caliperID)
        this.form.wheels[key].caliperPos = parseInt(this.form.wheels[key].caliperPos)
        this.form.wheels[key].tireMat = parseInt(this.form.wheels[key].tireMat)
        this.form.wheels[key].buyData.cash = parseInt(this.form.wheels[key].buyData.cash)
        this.form.wheels[key].buyData.credits = parseInt(this.form.wheels[key].buyData.credits)
        this.form.wheels[key].buyData.saleCash = parseInt(this.form.wheels[key].buyData.saleCash)
        this.form.wheels[key].buyData.saleCredits = parseInt(this.form.wheels[key].buyData.saleCredits)
      })

      // this.form.parts.bodyPartData.calipers.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.calipers[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.calipers[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.calipers[subKey].itemID = parseInt(this.form.parts.bodyPartData.calipers[subKey].itemID)
      //   this.form.parts.bodyPartData.calipers[subKey].order = parseInt(this.form.parts.bodyPartData.calipers[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.fenders.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.fenders[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.fenders[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.fenders[subKey].itemID = parseInt(this.form.parts.bodyPartData.fenders[subKey].itemID)
      //   this.form.parts.bodyPartData.fenders[subKey].order = parseInt(this.form.parts.bodyPartData.fenders[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.seats.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.seats[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.seats[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.seats[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.seats[subKey].itemID = parseInt(this.form.parts.bodyPartData.seats[subKey].itemID)
      //   this.form.parts.bodyPartData.seats[subKey].order = parseInt(this.form.parts.bodyPartData.seats[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.headlights.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.headlights[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.headlights[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.headlights[subKey].itemID = parseInt(this.form.parts.bodyPartData.headlights[subKey].itemID)
      //   this.form.parts.bodyPartData.headlights[subKey].order = parseInt(this.form.parts.bodyPartData.headlights[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.trunck.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.trunck[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.trunck[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.trunck[subKey].itemID = parseInt(this.form.parts.bodyPartData.trunck[subKey].itemID)
      //   this.form.parts.bodyPartData.trunck[subKey].order = parseInt(this.form.parts.bodyPartData.trunck[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.sideskirts.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.sideskirts[subKey].itemID = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].itemID)
      //   this.form.parts.bodyPartData.sideskirts[subKey].order = parseInt(this.form.parts.bodyPartData.sideskirts[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.taillights.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.taillights[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.taillights[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.taillights[subKey].itemID = parseInt(this.form.parts.bodyPartData.taillights[subKey].itemID)
      //   this.form.parts.bodyPartData.taillights[subKey].order = parseInt(this.form.parts.bodyPartData.taillights[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.spoiler.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.spoiler[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.spoiler[subKey].itemID = parseInt(this.form.parts.bodyPartData.spoiler[subKey].itemID)
      //   this.form.parts.bodyPartData.spoiler[subKey].order = parseInt(this.form.parts.bodyPartData.spoiler[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.bumpers.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.bumpers[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.bumpers[subKey].itemID = parseInt(this.form.parts.bodyPartData.bumpers[subKey].itemID)
      //   this.form.parts.bodyPartData.bumpers[subKey].order = parseInt(this.form.parts.bodyPartData.bumpers[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.exhaust.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.exhaust[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.exhaust[subKey].itemID = parseInt(this.form.parts.bodyPartData.exhaust[subKey].itemID)
      //   this.form.parts.bodyPartData.exhaust[subKey].order = parseInt(this.form.parts.bodyPartData.exhaust[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.frame.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.frame[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.frame[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.frame[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.frame[subKey].itemID = parseInt(this.form.parts.bodyPartData.frame[subKey].itemID)
      //   this.form.parts.bodyPartData.frame[subKey].order = parseInt(this.form.parts.bodyPartData.frame[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.doors.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.doors[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.doors[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.doors[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.doors[subKey].itemID = parseInt(this.form.parts.bodyPartData.doors[subKey].itemID)
      //   this.form.parts.bodyPartData.doors[subKey].order = parseInt(this.form.parts.bodyPartData.doors[subKey].order)
      // })
      //
      // this.form.parts.bodyPartData.rollcage.forEach((subItem, subKey) => {
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.cash = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.cash)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.credits = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.credits)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.expBuy = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.expBuy)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCash = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCash)
      //   this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCredits = parseInt(this.form.parts.bodyPartData.rollcage[subKey].buyData.saleCredits)
      //   this.form.parts.bodyPartData.rollcage[subKey].itemID = parseInt(this.form.parts.bodyPartData.rollcage[subKey].itemID)
      //   this.form.parts.bodyPartData.rollcage[subKey].order = parseInt(this.form.parts.bodyPartData.rollcage[subKey].order)
      // })



      this.form.parts.forEach((subItem, subKey) => {
        this.form.parts[subKey].buyData.cash = parseInt(this.form.parts[subKey].buyData.cash)
        this.form.parts[subKey].buyData.credits = parseInt(this.form.parts[subKey].buyData.credits)
        this.form.parts[subKey].buyData.expBuy = parseInt(this.form.parts[subKey].buyData.expBuy)
        this.form.parts[subKey].buyData.saleCash = parseInt(this.form.parts[subKey].buyData.saleCash)
        this.form.parts[subKey].buyData.saleCredits = parseInt(this.form.parts[subKey].buyData.saleCredits)
        this.form.parts[subKey].itemID = parseInt(this.form.parts[subKey].itemID)
        this.form.parts[subKey].order = parseInt(this.form.parts[subKey].order)
      })




      this.form.performance.power = parseInt(this.form.performance.power)
      this.form.performance.ecsceleration = parseInt(this.form.performance.ecsceleration)
      this.form.performance.torque = parseInt(this.form.performance.torque)
      this.form.performance.weight = parseInt(this.form.performance.weight)

      this.$store.dispatch('addNewCarInShop', this.form)
      console.log('submit!');
    }
  }
}
</script>