<template>
  <el-table
    :data="$store.state.clothsShop"
    style="width: 100%"
    max-height="100%"
  >
    <el-table-column
      prop="id"
      label="ID"
      width="150"
    >
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
      prop="type"
      label="Type"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.type"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="category"
      label="Name"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.category"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="itemID"
      label="Item ID"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.itemID"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="buyData.credits"
      label="Buy Data Credits"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.buyData.credits"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="buyData.cash"
      label="Buy Data Cash"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.buyData.cash"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="buyData.saleCredits"
      label="Buy Data Sale Credits"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.buyData.saleCredits"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="buyData.saleCash"
      label="Buy Data Sale Cash"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.buyData.saleCash"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="buyData.expBuy"
      label="Buy Data Exp Buy"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.buyData.expBuy"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      label="Operations"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-button
          type="text"
          size="small"
          @click.prevent="update(scope.row)"
        >
          Save
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-form
    ref="form"
    :model="clothsShop"
    label-width="120px"
    style="margin-top: 10px"
  >
    <el-form-item
      label="Cloth ID"
    >
      <el-input
        v-model="clothsShop.itemID"
        pattern="[0-9]+([\.,][0-9]+)?"
        placeholder="Ex.: 12"
        step="1"
        type="number"
      />
    </el-form-item>
    <el-form-item
      label="Category"
    >
      <el-select
        v-model="clothsShop.category"
        placeholder="Category..."
      >
        <el-option
          v-for="(category,key) in categories"
          :key="key"
          :label="category"
          :value="category"
        />
      </el-select>
    </el-form-item>
    <el-form-item
        label="Gender"
    >
      <el-select
          v-model="clothsShop.gender"
          placeholder="Category..."
      >
        <el-option
            v-for="(gender,key) in genders"
            :key="key"
            :label="gender"
            :value="gender"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Type"
    >
      <el-select
        v-model="clothsShop.type"
        placeholder="Type..."
      >
        <el-option
          v-for="(type,key) in types"
          :key="key"
          :label="type"
          :value="type"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Buy Data"
    >
      <el-divider />
      <el-form-item>
        <el-divider />
        <el-form-item
          label="Credits"
        >
          <el-input
            v-model="clothsShop.buyData.credits"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Cash"
        >
          <el-input
            v-model="clothsShop.buyData.cash"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Sale Credits"
        >
          <el-input
            v-model="clothsShop.buyData.saleCredits"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Sale Cash"
        >
          <el-input
            v-model="clothsShop.buyData.saleCash"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 100"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Exp Buy"
        >
          <el-input
            v-model="clothsShop.buyData.expBuy"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 10.00"
            step="0.01"
            type="number"
          />
        </el-form-item>
        <el-divider />
      </el-form-item>
      <el-divider />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      genders: ["f","m"],
      categories:[
        "Haircut",
        "TopClothes",
        "BottomClothes",
        "Shoes",
        "Accessories"
      ],
      types: [
        "Jacket",
        "Bomber",
        "Hoodie",
        "Tshirt",
        "Jeans",
        "Joggers",
        "Shorts",
        "Shoes",
        "Haircut",
        "Glasses",
        "Masks",
        "Helmets",
        "Boots",
        "Trainers",
        "Breeches",
        "Polo",
        "Gloves",
        "Hats",
        "Sweatshirt"
      ],
      clothsShop: {
        gender: "f",
        itemID: '',
        type: '',
        category: '',
        buyData: {
          cash:"0",
          credits:"0",
          expBuy:"0",
          saleCash:"0",
          saleCredits:"0"
        }
      }
    }
  },
  methods: {
    onSubmit() {
      this.clothsShop.itemID = parseInt(this.clothsShop.itemID)
      this.clothsShop.buyData.cash = parseInt(this.clothsShop.buyData.cash)
      this.clothsShop.buyData.credits = parseInt(this.clothsShop.buyData.credits)
      this.clothsShop.buyData.expBuy = parseInt(this.clothsShop.buyData.expBuy)
      this.clothsShop.buyData.saleCash = parseInt(this.clothsShop.buyData.saleCash)
      this.clothsShop.buyData.saleCredits = parseInt(this.clothsShop.buyData.saleCredits)
      this.$store.dispatch('addNewClothsShop',this.clothsShop)
    },
    update(row){
      this.clothsShop.itemID = parseInt(this.clothsShop.itemID)
      this.clothsShop.buyData.cash = parseInt(this.clothsShop.buyData.cash)
      this.clothsShop.buyData.credits = parseInt(this.clothsShop.buyData.credits)
      this.clothsShop.buyData.expBuy = parseInt(this.clothsShop.buyData.expBuy)
      this.clothsShop.buyData.saleCash = parseInt(this.clothsShop.buyData.saleCash)
      this.clothsShop.buyData.saleCredits = parseInt(this.clothsShop.buyData.saleCredits)
      this.$store.dispatch('updateClothsShop', row)
    },
    deleteRow(row){
      this.$store.dispatch('deleteClothsShop', {id: row.id})
    }
  }
}
</script>
