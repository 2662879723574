<template>
  <el-form
    label-width="120px"
  >
    <el-form-item
      label="Name"
    >
      <el-input
        v-model="form.name"
        placeholder="Name of event"
      />
    </el-form-item>
    <el-form-item
      label="Type"
    >
      <el-input
        v-model="form.type"
        placeholder="Type of event"
      />
    </el-form-item>
    <el-form-item
      label="Winners"
    >
      <div
        v-for="(winner, wkey) in form.winners"
        :key="wkey"
      >
        <el-divider />
        <el-form-item
          label="Cars"
        >
          <div
            v-for="(_car,key) in winner.cars"
            :key="key"
          >
            <el-button
              type="text"
              @click="selectUpdatedCar(wkey,key)"
            >
              {{ _car.id }}
            </el-button>
          </div>
          <el-popover
            :width="400"
            placement="right"
            trigger="click"
          >
            <template #reference>
              <el-button>+</el-button>
            </template>
            <el-table :data="cars">
              <el-table-column
                label="Id"
                property="id"
                width="100"
              />
              <el-table-column
                label="Car Model"
                property="carModel"
                width="150"
              />
              <el-table-column
                label="Car Id"
                property="carID"
                width="100"
              />
              <el-table-column>
                <template
                  #default="scope"
                >
                  <el-button
                    @click="selectCar(scope.row)"
                  >
                    select
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-popover>
          <el-dialog
            v-model="dialogTableVisible"
            title="Car"
          >
            <div>
              <el-form
                  ref="form"
                  :model="car"
                  label-width="120px"
              >
                <el-form-item
                    label="Link to image"
                >
                  <el-input
                      v-model="car.imgLink"
                      placeholder="http://example"
                  />
                </el-form-item>
                <el-form-item
                    label="Car model"
                >
                  <el-input
                      v-model="car.carModel"
                      placeholder="Lamborgini aventador"
                  />
                </el-form-item>
                <el-form-item
                    label="Car ID"
                >
                  <el-input
                      v-model="car.carID"
                      placeholder="Ex.: 12"
                      type="number"
                  />
                </el-form-item>
                <el-form-item label="Body color">
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.bodyColor[0]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="R"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.bodyColor[1]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="G"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.bodyColor[2]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="B"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.bodyColor[3]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="A"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item
                    label="Tint color"
                >
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.tintColor[0]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="R"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.tintColor[1]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="G"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.tintColor[2]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="B"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.tintColor[3]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="A"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item
                    label="Underglow color"
                >
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.underglowColor[0]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="R"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.underglowColor[1]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="G"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.underglowColor[2]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="B"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.underglowColor[3]"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="A"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item
                    label="Meterial ID"
                >
                  <el-col
                      :span="2"
                      class="line"
                  >
                    <el-input
                        v-model="car.materialID"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="Ex.: 12"
                        step="0.01"
                        type="number"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item
                    label="Exp Car"
                >
                  <el-input
                      v-model="car.expCar"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      placeholder="Ex.: 1"
                      step="0.01"
                      type="number"
                  />
                </el-form-item>
                <el-form-item label="Body parts">
                  <el-divider />
                  <el-form-item
                      v-for="(_,key) in car.parts"
                      :key="key"
                  >
                    <el-form-item
                        label="Type"
                    >
                      <el-select
                          v-model="car.parts[key].type"
                          placeholder="Type..."
                      >
                        <el-option
                            v-for="(category,key) in $store.state.partNames"
                            :key="key"
                            :label="category.partName"
                            :value="category.partName"
                        />
                      </el-select>
                    </el-form-item>


                    <el-form-item
                        label="Order"
                    >
                      <el-input
                          v-model="car.parts[key].order"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 12"
                          step="1"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Item ID"
                    >
                      <el-input
                          v-model="car.parts[key].itemID"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 12"
                          step="1"
                          type="number"
                      />
                    </el-form-item>
                    <el-divider />
                    <el-form-item
                        label="Buy Data"
                    >
                      <el-form-item>
                        <el-form-item
                            label="Credits"
                        >
                          <el-input
                              v-model="car.parts[key].buyData.credits"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              placeholder="Ex.: 100"
                              step="0.01"
                              type="number"
                          />
                        </el-form-item>
                        <el-form-item
                            label="Cash"
                        >
                          <el-input
                              v-model="car.parts[key].buyData.cash"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              placeholder="Ex.: 100"
                              step="0.01"
                              type="number"
                          />
                        </el-form-item>
                        <el-form-item
                            label="Sale Credits"
                        >
                          <el-input
                              v-model="car.parts[key].buyData.saleCredits"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              placeholder="Ex.: 100"
                              step="0.01"
                              type="number"
                          />
                        </el-form-item>
                        <el-form-item
                            label="Sale Cash"
                        >
                          <el-input
                              v-model="car.parts[key].buyData.saleCash"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              placeholder="Ex.: 100"
                              step="0.01"
                              type="number"
                          />
                        </el-form-item>
                        <el-form-item
                            label="Exp Buy"
                        >
                          <el-input
                              v-model="car.parts[key].buyData.expBuy"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              placeholder="Ex.: 10.00"
                              step="0.01"
                              type="number"
                          />
                        </el-form-item>
                      </el-form-item>
                      <el-button
                          @click="removePartData(key)"
                      >
                        Remove Part Data
                      </el-button>
                    </el-form-item>
                    <el-divider />
                  </el-form-item>
                  <el-button
                      @click="addPartData"
                  >
                    Add Part Data
                  </el-button>
                  <el-col
                      :span="12"
                  />
                </el-form-item>


                <el-form-item
                    label="Wheel Data"
                >
                  <el-divider />
                  <el-form-item
                      v-for="(item, key) in car.wheels"
                      :key="key"
                      :label="'Body Data ' + key "
                  >
                    <el-divider />
                    <el-form-item label="Item ID">
                      <el-input
                          v-model="car.wheels[key].WheelID"
                      />
                    </el-form-item>
                    <el-form-item
                        label="First color"
                    >
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].firstColor[0]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="R"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].firstColor[1]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="G"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].firstColor[2]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="B"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].firstColor[3]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="A"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                    </el-form-item>
                    <el-form-item
                        label="Second color"
                    >
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].secondColor[0]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="R"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].secondColor[1]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="G"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].secondColor[2]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="B"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                      <el-col
                          :span="2"
                          class="line"
                      >
                        <el-input
                            v-model="car.wheels[key].secondColor[3]"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="A"
                            step="0.01"
                            type="number"
                        />
                      </el-col>
                    </el-form-item>
                    <el-form-item
                        label="Caliper ID"
                    >
                      <el-input
                          v-model="car.wheels[key].caliperID"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 1"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Caliper Pos"
                    >
                      <el-input
                          v-model="car.wheels[key].caliperPos"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 12.00"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Brake Disk Material"
                    >
                      <el-input
                          v-model="car.wheels[key].brakeDiskMat"
                          placeholder="Ex.: 12.00"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Tire Material"
                    >
                      <el-input
                          v-model="car.wheels[key].tireMat"
                          placeholder="Ex.: 12.00"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Brake Disk Position"
                    >
                      <el-input
                          v-model="car.wheels[key].brakeDiskPos"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 10.00"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>

                    <el-form-item
                        label="Buy data"
                    >
                      <el-divider />
                      <!--            <el-form-item-->
                      <!--              label="Is Buyed"-->
                      <!--            >-->
                      <!--              <el-switch-->
                      <!--                v-model="form.parts.wheels[key].buyData.isBuyed"-->
                      <!--              />-->
                      <!--            </el-form-item>-->
                      <el-form-item
                          label="Credits"
                      >
                        <el-input
                            v-model="car.wheels[key].buyData.credits"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="Ex.: 100"
                            step="0.01"
                            type="number"
                        />
                      </el-form-item>
                      <el-form-item
                          label="Cash"
                      >
                        <el-input
                            v-model="car.wheels[key].buyData.cash"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="Ex.: 100"
                            step="0.01"
                            type="number"
                        />
                      </el-form-item>
                      <el-form-item
                          label="Sale Credits"
                      >
                        <el-input
                            v-model="car.wheels[key].buyData.saleCredits"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="Ex.: 100"
                            step="0.01"
                            type="number"
                        />
                      </el-form-item>
                      <el-form-item
                          label="Sale Cash"
                      >
                        <el-input
                            v-model="car.wheels[key].buyData.saleCash"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            placeholder="Ex.: 100"
                            step="0.01"
                            type="number"
                        />
                      </el-form-item>
                      <el-divider />
                      <el-button
                          @click="removeWheel(key)"
                      >
                        Remove Wheel
                      </el-button>
                    </el-form-item>
                    <el-divider />
                  </el-form-item>
                  <el-col
                      v-if="car.wheels.length < 2"
                      :span="12"
                  >
                    <el-button
                        @click="addWheel"
                    >
                      New Wheel Data
                    </el-button>
                  </el-col>
                </el-form-item>
                <el-form-item label="Buy Data">
                  <el-divider />
                  <el-form-item>
                    <el-divider />
                    <el-form-item
                        label="Credits"
                    >
                      <el-input
                          v-model="car.buyData.credits"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Cash"
                    >
                      <el-input
                          v-model="car.buyData.cash"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Sale Credits"
                    >
                      <el-input
                          v-model="car.buyData.saleCredits"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Sale Cash"
                    >
                      <el-input
                          v-model="car.buyData.saleCash"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Exp Buy"
                    >
                      <el-input
                          v-model="car.buyData.expBuy"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 10.00"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-divider />
                  </el-form-item>
                  <el-divider />
                </el-form-item>

                <el-form-item
                    label="Performance"
                >
                  <el-divider />
                  <el-form-item>
                    <el-divider />
                    <el-form-item
                        label="Power"
                    >
                      <el-input
                          v-model="car.performance.power"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Ecsceleration"
                    >
                      <el-input
                          v-model="car.performance.ecsceleration"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Torque"
                    >
                      <el-input
                          v-model="car.performance.torque"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Weight"
                    >
                      <el-input
                          v-model="car.performance.weight"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          placeholder="Ex.: 100"
                          step="0.01"
                          type="number"
                      />
                    </el-form-item>
                    <el-divider />
                  </el-form-item>
                  <el-divider />
                </el-form-item>
                <el-form-item>
                  <el-button
                      v-if="isUpdateMode === false"
                      type="primary"
                      @click="addCar(wkey)"
                  >
                    Create
                  </el-button>
                  <el-button
                      v-if="isUpdateMode === true"
                      type="primary"
                      @click="updateSelectedCar(wkey)"
                  >
                    Update
                  </el-button>
                  <el-button
                      type="primary"
                      @click="deleteCar(wkey)"
                  >
                    Delete
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </el-form-item>

        <el-form-item
          label="Cash"
        >
          <el-input
            v-model="form.winners[wkey].cash"
            placeholder="Ex.: 12000"
          />
        </el-form-item>
        <el-form-item
          label="Exp"
        >
          <el-input
            v-model="form.winners[wkey].exp"
            placeholder="Ex.: 1000"
          />
        </el-form-item>
        <el-form-item
          label="Money"
        >
          <el-input
            v-model="form.winners[wkey].money"
            placeholder="Ex.: 10"
          />
        </el-form-item>
        <el-divider />
      </div>
      <el-button
        @click="addWinner"
      >
        +
      </el-button>
    </el-form-item>
    <el-form-item label="Date of event">
      <div class="block">
        <el-date-picker
          v-model="form.dateTime"
          :default-time="defaultTime1"
          end-placeholder="End Date"
          start-placeholder="Start Date"
          type="datetimerange"
        />
      </div>
    </el-form-item>
    <el-form-item>
      <el-button
        v-if="isUpdateMode === false"
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
      <el-button
        v-if="isUpdateMode === true"
        type="primary"
        @click="onUpdate"
      >
        Update
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      selectedId: null,
      selectedKey: null,
      selectedCarIsUpdate: false,
      defaultTime1: [new Date(2000, 1, 1, 12, 0, 0)],
      dialogTableVisible: false,
      isUpdateMode: false,
      submit: {},
      car: {
        imgLink:'',
        expCar: '',
        carModel: '',
        carID: '',
        materialID: '',
        bodyColor: [],
        tintColor: [],
        underglowColor: [],
        // bodyData: [],
        // wheelData: [],
        buyData: {},
        performance: {},
        wheels: [],
        parts: []
      },
      form: {
        winners: [{
          cars: [],
          exp: 0,
          cash: 0,
          money: 0
        }],
        dateTime: []
      }
    }
  },
  computed: {
    cars() {
      return this.$store.state.cars
    },
  },
  mounted() {
    console.log(new Date(new Date('2020/01/01/12:00:00').toUTCString()).getTime())
    this.$store.dispatch("getCars")
    if (this.$route.params.id) {
      this.isUpdateMode = true
      this.form.dateTime[0] = new Date(this.form.dateTime[0]);
      this.form.dateTime[1] = new Date(this.form.dateTime[1]);
      console.log(this.$route.params.id)
      let temp = this.$store.state.eventsCars.filter(item => item.id === this.$route.params.id)[0]
      temp.dateTime[0] = new Date(temp.dateTime[0]);
      temp.dateTime[1] = new Date(temp.dateTime[1]);
      temp.winners.map((i) => {
        i.cars.map((item) => {
          item.bodyColor = item.bodyColor.split('/')
          // temp.bodyColor.forEach((item,key) => {
          //   temp.bodyColor[key] = parseInt(temp.bodyColor[key]);
          // })
          item.tintColor = item.tintColor.split('/')
          // temp.tintColor.forEach((item,key) => {
          //   temp.tintColor[key] = parseInt(temp.tintColor[key]);
          // })
          item.underglowColor = item.underglowColor.split('/')
          // temp.underglowColor.forEach((item,key) => {
          //   temp.underglowColor[key] = parseInt(temp.underglowColor[key]);
          // })

          item.wheels.map((data, key) => {
            item.wheels[key].firstColor = item.wheels[key].firstColor.split('/')
            item.wheels[key].secondColor = item.wheels[key].secondColor.split('/')
          })
        })
      })



      this.form = temp
      console.log("Form: " + this.form)
    } else {
      this.form.dateTime[0] = new Date(this.form.dateTime[0]);
      this.form.dateTime[1] = new Date(this.form.dateTime[1]);
    // this.form.winners.cars.map((item) => {
    //
    //     item.bodyColor = item.bodyColor.split('/')
    //     // temp.bodyColor.forEach((item,key) => {
    //     //   temp.bodyColor[key] = parseInt(temp.bodyColor[key]);
    //     // })
    //     item.tintColor = item.tintColor.split('/')
    //     // temp.tintColor.forEach((item,key) => {
    //     //   temp.tintColor[key] = parseInt(temp.tintColor[key]);
    //     // })
    //     item.underglowColor = item.underglowColor.split('/')
    //     // temp.underglowColor.forEach((item,key) => {
    //     //   temp.underglowColor[key] = parseInt(temp.underglowColor[key]);
    //     // })
    //     item.bodyData.map((data, key) => {
    //       item.bodyData[key].Pos6 = item.bodyData[key].Pos6.split('/')
    //     })
    //     item.wheelData.map((data, key) => {
    //       item.wheelData[key].firstColor = item.wheelData[key].firstColor.split('/')
    //       item.wheelData[key].secondColor = item.wheelData[key].secondColor.split('/')
    //     })
    //   });
      // this.initData();
    }
  },

  methods: {
    initData() {
      this.car.bodyColor[0] = "0.00"
      this.car.bodyColor[1] = "0.00"
      this.car.bodyColor[2] = "0.00"
      this.car.bodyColor[3] = "0.00"

      this.car.tintColor[0] = "0.00"
      this.car.tintColor[1] = "0.00"
      this.car.tintColor[2] = "0.00"
      this.car.tintColor[3] = "0.00"

      this.car.underglowColor[0] = "0.00"
      this.car.underglowColor[1] = "0.00"
      this.car.underglowColor[2] = "0.00"
      this.car.underglowColor[3] = "0.00"
    },

    addWinner(){
      this.form.winners.push({
        cars: [],
        exp: 0,
        cash: 0,
        money: 0
      })
    },

    addCar(key) {
      this.car.bodyColor = this.car.bodyColor.join('/')
      this.car.tintColor = this.car.tintColor.join('/')
      this.car.underglowColor = this.car.underglowColor.join('/')
      this.car.wheels.map((item, key) => {
        this.car.wheels[key].firstColor = this.car.wheels[key].firstColor.join('/')
        this.car.wheels[key].secondColor = this.car.wheels[key].secondColor.join('/')
        this.car.wheels[key].WheelID = parseInt(this.car.wheels[key].WheelID)
        this.car.wheels[key].brakeDiskMat = parseInt(this.car.wheels[key].brakeDiskMat)
        this.car.wheels[key].brakeDiskPos = parseInt(this.car.wheels[key].brakeDiskPos)
        this.car.wheels[key].caliperID = parseInt(this.car.wheels[key].caliperID)
        this.car.wheels[key].caliperPos = parseInt(this.car.wheels[key].caliperPos)
        this.car.wheels[key].tireMat = parseInt(this.car.wheels[key].tireMat)
        this.car.wheels[key].buyData.cash = parseInt(this.car.wheels[key].buyData.cash)
        this.car.wheels[key].buyData.credits = parseInt(this.car.wheels[key].buyData.credits)
        this.car.wheels[key].buyData.saleCash = parseInt(this.car.wheels[key].buyData.saleCash)
        this.car.wheels[key].buyData.saleCredits = parseInt(this.car.wheels[key].buyData.saleCredits)

      })
      this.form.winners[key].cars.push(this.car);
      this.dialogTableVisible = false
    },

    deleteCar(wkey){
      this.form.winners[wkey].cars = this.form.winners[wkey].cars.filter((item,key) => key !== this.selectedKey)
      this.dialogTableVisible = false
      this.selectedCarIsUpdate = false
    },

    updateSelectedCar(wkey) {
      this.car.bodyColor = this.car.bodyColor.join('/')
      this.car.tintColor = this.car.tintColor.join('/')
      this.car.underglowColor = this.car.underglowColor.join('/')
      this.car.wheels.map((item, key) => {
        this.car.wheels[key].firstColor = this.car.wheels[key].firstColor.join('/')
        this.car.wheels[key].secondColor = this.car.wheels[key].secondColor.join('/')
        this.car.wheels[key].WheelID = parseInt(this.car.wheels[key].WheelID)
        this.car.wheels[key].brakeDiskMat = parseInt(this.car.wheels[key].brakeDiskMat)
        this.car.wheels[key].brakeDiskPos = parseInt(this.car.wheels[key].brakeDiskPos)
        this.car.wheels[key].caliperID = parseInt(this.car.wheels[key].caliperID)
        this.car.wheels[key].caliperPos = parseInt(this.car.wheels[key].caliperPos)
        this.car.wheels[key].tireMat = parseInt(this.car.wheels[key].tireMat)
        this.car.wheels[key].buyData.cash = parseInt(this.car.wheels[key].buyData.cash)
        this.car.wheels[key].buyData.credits = parseInt(this.car.wheels[key].buyData.credits)
        this.car.wheels[key].buyData.saleCash = parseInt(this.car.wheels[key].buyData.saleCash)
        this.car.wheels[key].buyData.saleCredits = parseInt(this.car.wheels[key].buyData.saleCredits)

      })

      this.car.performance.power = parseInt(this.car.performance.power)
      this.car.performance.ecsceleration = parseInt(this.car.performance.ecsceleration)
      this.car.performance.torque = parseInt(this.car.performance.torque)
      this.car.performance.weight = parseInt(this.car.performance.weight)


      this.car.parts.forEach((subItem, subKey) => {
        this.car.parts[subKey].buyData.cash = parseInt(this.car.parts[subKey].buyData.cash)
        this.car.parts[subKey].buyData.credits = parseInt(this.car.parts[subKey].buyData.credits)
        this.car.parts[subKey].buyData.expBuy = parseInt(this.car.parts[subKey].buyData.expBuy)
        this.car.parts[subKey].buyData.saleCash = parseInt(this.car.parts[subKey].buyData.saleCash)
        this.car.parts[subKey].buyData.saleCredits = parseInt(this.car.parts[subKey].buyData.saleCredits)
        this.car.parts[subKey].itemID = parseInt(this.car.parts[subKey].itemID)
        this.car.parts[subKey].order = parseInt(this.car.parts[subKey].order)
      })




      this.car.performance.power = parseInt(this.car.performance.power)
      this.car.performance.ecsceleration = parseInt(this.car.performance.ecsceleration)
      this.car.performance.torque = parseInt(this.car.performance.torque)
      this.car.performance.weight = parseInt(this.car.performance.weight)



      this.form.winners[wkey].cars.map((item, key) => {
        if (key === this.selectedId) {
          this.form.winners[wkey].cars[key] = this.car
        }
      });
      console.log("Update Selected car: " + this.form.cars)
      this.selectedCarIsUpdate = false;
      this.dialogTableVisible = false

    },
    selectCar(row) {
      this.selectedId = row.id
      this.car = this.$store.state.cars.filter((item) => item.id === row.id)[0]
      this.car.bodyColor = this.car.bodyColor.split('/')
      // temp.bodyColor.forEach((item,key) => {
      //   temp.bodyColor[key] = parseInt(temp.bodyColor[key]);
      // })
      this.car.tintColor = this.car.tintColor.split('/')
      // temp.tintColor.forEach((item,key) => {
      //   temp.tintColor[key] = parseInt(temp.tintColor[key]);
      // })
      this.car.underglowColor = this.car.underglowColor.split('/')
      // temp.underglowColor.forEach((item,key) => {
      //   temp.underglowColor[key] = parseInt(temp.underglowColor[key]);
      // })

      this.car.wheels.map((item, key) => {
        this.car.wheels[key].firstColor = this.car.wheels[key].firstColor.split('/')
        this.car.wheels[key].secondColor = this.car.wheels[key].secondColor.split('/')
      })

      this.car.parts.forEach((subItem, subKey) => {
        this.car.parts[subKey].buyData.cash = parseInt(this.car.parts[subKey].buyData.cash)
        this.car.parts[subKey].buyData.credits = parseInt(this.car.parts[subKey].buyData.credits)
        this.car.parts[subKey].buyData.expBuy = parseInt(this.car.parts[subKey].buyData.expBuy)
        this.car.parts[subKey].buyData.saleCash = parseInt(this.car.parts[subKey].buyData.saleCash)
        this.car.parts[subKey].buyData.saleCredits = parseInt(this.car.parts[subKey].buyData.saleCredits)
        this.car.parts[subKey].itemID = parseInt(this.car.parts[subKey].itemID)
        this.car.parts[subKey].order = parseInt(this.car.parts[subKey].order)
      })

      this.car.performance.power = parseInt(this.car.performance.power)
      this.car.performance.ecsceleration = parseInt(this.car.performance.ecsceleration)
      this.car.performance.torque = parseInt(this.car.performance.torque)
      this.car.performance.weight = parseInt(this.car.performance.weight)

      this.dialogTableVisible = true
    },

    selectUpdatedCar(wkey,k) {
      this.selectedKey = k;

      this.car = this.form.winners[wkey].cars.filter((item, key) => key === k)[0]
      // this.car.bodyColor = this.car.bodyColor.toString().split('/')
      // // temp.bodyColor.forEach((item,key) => {
      // //   temp.bodyColor[key] = parseInt(temp.bodyColor[key]);
      // // })
      // this.car.tintColor = this.car.tintColor.toString().split('/')
      // // temp.tintColor.forEach((item,key) => {
      // //   temp.tintColor[key] = parseInt(temp.tintColor[key]);
      // // })
      // this.car.underglowColor = this.car.underglowColor.toString().split('/')
      // // temp.underglowColor.forEach((item,key) => {
      // //   temp.underglowColor[key] = parseInt(temp.underglowColor[key]);
      // // })
      // this.car.bodyData.forEach((item,key) => {
      //   this.car.bodyData[key].Pos6 = this.car.bodyData[key].Pos6.toString().split('/')
      // })
      // this.car.wheelData.forEach((item,key) => {
      //   this.car.wheelData[key].firstColor = this.car.wheelData[key].firstColor.toString().split('/')
      //   this.car.wheelData[key].secondColor = this.car.wheelData[key].secondColor.toString().split('/')
      // })
      this.dialogTableVisible = true
      this.selectedCarIsUpdate = true;
      console.log(this.car)
    },


    addWheel(){
      this.car.wheels.push({
        order: "0",
        wheelID : "0",
        brakeDiskMat: "0",
        brakeDiskPos: "0",
        caliperID: "0",
        caliperPos: "0",
        firstColor: "0",
        secondColor: "0",
        tireMat: "0",
        buyData:{
          cash:"0",
          credits:"0",
          expBuy:"0",
          saleCash:"0",
          saleCredits:"0"
        }
      })
    },

    addPartData(){
      this.car.parts.push({
        type: '',
        order: 0,
        itemID:0,
        buyData:{
          cash:0,
          credits:0,
          expBuy:0,
          saleCash:0,
          saleCredits:0
        }
      });
    },

    removePartData(key) {
      this.car.parts = this.car.parts.filter((value, k) => k !== key);
    },

    removeWheel(key) {
      this.car.wheels = this.car.wheels.filter((value, k) => k !== key);
    },





    onUpdate() {
       // this.form.dateTime[0] = this.form.dateTime[0];
       // this.form.dateTime[1] = this.form.dateTime[1];
      // console.log(parseInt((new Date(this.form.dateTime[0].toUTCString()).getTime()).toString() + "0"));
      // console.log(new Date(this.form.dateTime[0].toUTCString()))
      this.isUpdateMode = false;
      this.form.winners.map((i) => {
        // this.form.winners.performance.power = parseInt(this.form.winners.performance.power)
        // this.form.winners.performance.ecsceleration = parseInt(this.form.winners.performance.ecsceleration)
        // this.form.winners.performance.torque = parseInt(this.form.winners.performance.torque)
        // this.form.winners.performance.weight = parseInt(this.form.winners.performance.weight)
        i.cars.map(item => {
          item.bodyColor = item.bodyColor instanceof Array ? item.bodyColor.join('/') : item.bodyColor
          item.tintColor = item.tintColor instanceof Array ? item.tintColor.join('/') : item.tintColor
          item.underglowColor =  item.underglowColor instanceof Array ? item.underglowColor.join('/') : item.underglowColor
          item.wheels.map((i) => {
            i.firstColor = i.firstColor instanceof Array ? i.firstColor.join('/') : i.firstColor
            i.secondColor = i.secondColor instanceof Array ? i.secondColor.join('/') : i.secondColor
          })
          item.parts.forEach((subItem, subKey) => {
            item.parts[subKey].buyData.cash = parseInt(item.parts[subKey].buyData.cash)
            item.parts[subKey].buyData.credits = parseInt(item.parts[subKey].buyData.credits)
            item.parts[subKey].buyData.expBuy = parseInt(item.parts[subKey].buyData.expBuy)
            item.parts[subKey].buyData.saleCash = parseInt(item.parts[subKey].buyData.saleCash)
            item.parts[subKey].buyData.saleCredits = parseInt(item.parts[subKey].buyData.saleCredits)
            item.parts[subKey].itemID = parseInt(item.parts[subKey].itemID)
            item.parts[subKey].order = parseInt(item.parts[subKey].order)
          })
        })
      })


      // this.form.cars[0].bodyColor = this.car.bodyColor.join('/')
      // this.form.cars[0].tintColor = this.car.tintColor.join('/')
      // this.form.cars[0].underglowColor = this.car.underglowColor.join('/')
      // this.form.cars[0].bodyData.forEach((item,key) => {
      //   this.form.cars[0].bodyData[key].Pos6 = this.form.cars[0].bodyData[key].Pos6.join('/')
      // })
      // this.form.cars[0].wheelData.forEach((item,key) => {
      //   this.form.cars[0].wheelData[key].firstColor = this.form.cars[0].wheelData[key].firstColor.join('/')
      //   this.form.cars[0].wheelData[key].secondColor = this.form.cars[0].wheelData[key].secondColor.join('/')
      // })
      this.$store.dispatch('updateEventsCar', this.form)
      this.$router.push('/')
      console.log('submit!');
    },
    onSubmit() {
      // this.form.dateTime[0] = this.form.dateTime[0];
      // this.form.dateTime[1] = this.form.dateTime[1];
      // this.form.cars.map(item => {
      //   item.bodyColor = item.bodyColor.join('/')
      //   item.tintColor = item.tintColor.join('/')
      //   item.underglowColor = item.underglowColor.join('/')
      //   item.bodyData.map((i) => {
      //     i.Pos6 = i.Pos6.join('/')
      //   })
      //   item.wheelData.map((i) => {
      //     i.firstColor = i.firstColor.join('/')
      //     i.secondColor = i.secondColor.join('/')
      //   })
      // })
      // this.car.bodyColor = this.car.bodyColor.join('/')
      // this.car.tintColor = this.car.tintColor.join('/')
      // this.car.underglowColor = this.car.underglowColor.join('/')
      // this.car.bodyData.forEach((item,key) => {
      //   this.car.bodyData[key].Pos6 = this.car.bodyData[key].Pos6.join('/')
      // })
      // this.car.wheelData.forEach((item,key) => {
      //   this.car.wheelData[key].firstColor = this.car.wheelData[key].firstColor.join('/')
      //   this.car.wheelData[key].secondColor = this.car.wheelData[key].secondColor.join('/')
      // })
      this.$store.dispatch('addNewCarInEvents', this.form)
      this.$router.push('/')
      console.log('submit!');
    }
  }
}
</script>