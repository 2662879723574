<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
  >
    <el-form-item
      label="Sex"
    >
      <el-select
        v-model="avatar.sex"
        placeholder="Sex..."
      >
        <el-option
          v-for="(sex,key) in sexs"
          :key="key"
          :label="sex"
          :value="sex"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Character ID"
    >
        <el-input
            v-model="avatar.characterId"
            size="small"
            style="text-align:center"
        />
  </el-form-item>
    <el-form-item
      label="Race"
    >
      <el-select
        v-model="avatar.race"
        placeholder="Race..."
      >
        <el-option
          v-for="(race,key) in races"
          :key="key"
          :label="race"
          :value="race"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Cloth"
    >
      <el-divider />
      <el-form-item
        v-for="(item,key) in avatar.clothData"
        :key="key"
      >
        <el-form-item
          label="Cloth ID"
        >
          <el-input
            v-model="avatar.clothData[key].itemID"
            pattern="[0-9]+([\.,][0-9]+)?"
            placeholder="Ex.: 12"
            step="1"
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="Category"
        >
          <el-select
            v-model="avatar.clothData[key].category"
            placeholder="Category..."
          >
            <el-option
              v-for="(category,ckey) in categories"
              :key="ckey"
              :label="category"
              :value="category"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Type"
        >
          <el-select
            v-model="avatar.clothData[key].type"
            placeholder="Type..."
          >
            <el-option
              v-for="(type,tkey) in types"
              :key="tkey"
              :label="type"
              :value="type"
            />
          </el-select>
        </el-form-item>
        <el-button
          @click="removeClothData(key)"
        >
          Remove Cloth
        </el-button>
        <el-divider />
      </el-form-item>
      <el-button
        @click="addClothData"
      >
        Add Cloth
      </el-button>
    </el-form-item>


    <el-form-item>
      <el-button
        v-if="isUpdateMode === false"
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
      <el-button
        v-if="isUpdateMode === true"
        type="primary"
        @click="onUpdate"
      >
        Update
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "Avatars",
  data(){
    return {
      isUpdateMode: false,
      sexs: ["Male", "Female"],
      races: ["Asian","Afro","European"],
      categories:[
        "Haircut",
        "TopClothes",
        "BottomClothes",
        "Shoes",
        "Accessories"
      ],
      types: [
          "Jacket",
          "Bomber",
          "Hoodie",
          "Tshirt",
          "Jeans",
          "Joggers",
          "Shorts",
          "Shoes",
          "Haircut",
          "Glasses",
          "Masks",
          "Helmets",
          "Boots",
          "Trainers",
          "Breeches",
          "Polo",
          "Gloves",
          "Hats",
          "Sweatshirt"
      ],
      avatar: {
        characterId: 0,
        sex: '',
        race: '',
        clothData: []
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.isUpdateMode = true
      this.avatar = this.$store.state.avatars.filter(item => item.id === this.$route.params.id)[0]
    }
  },
  methods: {
    addClothData(){
      this.avatar.clothData.push({
        category: '',
        type: '',
        itemID: ''
      })
    },

    removeClothData(key){
      this.avatar.clothData = this.avatar.clothData.filter((item, k) => {
          return key !== k;
      });
    },

    onSubmit(){
      if(this.avatar.clothData) {
        this.avatar.clothData.map((item, key) => {
          this.avatar.clothData[key].itemID = parseInt(this.avatar.clothData[key].itemID)
          this.avatar.characterId = parseInt(this.avatar.characterId)
        })
      }
      this.$store.dispatch('addNewAvatar', this.avatar)
      this.$router.push({name: "Home"})
    },

    onUpdate(){
      if(this.avatar.clothData){
        this.avatar.clothData.map((item,key) => {
          this.avatar.clothData[key].itemID = parseInt(this.avatar.clothData[key].itemID)
          this.avatar.characterId = parseInt(this.avatar.characterId)
        })
      }

      this.$store.dispatch('updateAvatar', this.avatar)
      this.$router.push({name: "Home"})
    }
  }
}
</script>

<style scoped>

</style>