<template>
  <!--  <el-col>-->
  <!--    <el-table-->
  <!--        :data="$store.state.pos6"-->
  <!--        style="width: 100%">-->
  <!--      <el-table-column-->
  <!--          prop="partPlace"-->
  <!--          label="Part Place"-->
  <!--          width="180"/>-->
  <!--      <el-table-column-->
  <!--          label="Actions"-->
  <!--          width="180">-->
  <!--        <el-button type="danger">Remove</el-button>-->
  <!--      </el-table-column>-->
  <!--    </el-table>-->
  <!--  </el-col>-->

  <el-table
    :data="$store.state.pos6"
    style="width: 100%"
    max-height="100%"
  >
    <el-table-column
      prop="date"
      label="ID"
      width="150"
    >
      <template
        #default="scope"
      >
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
      prop="name"
      label="Name"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.partPlace"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="Operations"
      width="120"
    >
      <template #default="scope">
        <el-button
          type="text"
          size="small"
          @click.prevent="update(scope.row)"
        >
          Save
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    style="margin-top: 10px"
  >
    <el-form-item
      label="Part Place"
    >
      <el-input
        v-model="form.partPlace"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      form:{
        partPlace: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('addNewPos6Type',this.form)
    },
    update(row){
      this.$store.dispatch('updatePos6',{id: row.id, partPlace: row.partPlace})
    },
    deleteRow(row){
      this.$store.dispatch('deletePos6',{id: row.id})
    }
  }
}
</script>