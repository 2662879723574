<template>
  <el-table
    :data="$store.state.partNames"
    style="width: 100%"
    max-height="100%"
  >
    <el-table-column
      prop="date"
      label="ID"
      width="150"
    >
      <template #default="scope">
        {{ scope.row.id }}
      </template>
    </el-table-column>
    <el-table-column
      prop="name"
      label="Name"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-input
          v-model="scope.row.partName"
          size="small"
          style="text-align:center"
        />
      </template>
    </el-table-column>
    <el-table-column
      label="Operations"
      width="120"
    >
      <template
        #default="scope"
      >
        <el-button
          type="text"
          size="small"
          @click.prevent="update(scope.row)"
        >
          Save
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    style="margin-top: 10px"
  >
    <el-form-item
      label="Part Name"
    >
      <el-input
        v-model="form.partName"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Create
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      editMode: false,
      form:{
        partName: ''
      }
    }
  },
  methods: {
    onSubmit() {
      this.form.partName = this.form.partName.toUpperCase()
      this.$store.dispatch('addPartName',this.form)
    },
    update(row){
      this.$store.dispatch('updatePartNameData', {id: row.id, partName: row.partName.toUpperCase()})
    },
    deleteRow(row){
      this.$store.dispatch('deletePartName', {id: row.id})
    }
  }
}
</script>